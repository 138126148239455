<template>
  <div class="wisuda">
    <div class="navbar">
      <div @click="$router.go(-1)">
        <back />
      </div>
      <div>Pendaftaran Wisuda</div>
    </div>

    <div class="body">
      <div class="loading" v-if="loading">
        <p>
          <loading />
        </p>
      </div>
      <div v-else>
        <div class="fitur-flag" v-show="!fiturFlag">
          <h3 style="text-align: center; font-weight: 500; margin-top: 150px">
            Tidak ada jadwal Pendaftaran Wisuda
          </h3>
        </div>

        <div class="invalid" v-show="data.invalid">
          <p style="text-align: center;margin-top: 100px;">
            {{ data.message }}
          </p>
        </div>

        <div class="registered" v-show="data.id && data.alasan_ditolak">
          <p class="notif">
            <b>Deposit ditolak dengan alasan: </b> <br />
            <span style="padding: 10px 0;display: block;">
              {{ data.alasan_ditolak }}
            </span>
          <ol>
            <li>Silakan upload ulang bukti pembayaran Deposit</li>
            <!-- <li>Batas transfer deposit pada tanggal 5 Maret 2022</li> -->
          </ol>
          </p>

          <form @submit.prevent="upload" style="margin-top: 30px;">
            <div v-show="filePrev">
              <img :src="filePrev" width="100%">
              <br /><br />
            </div>
            <div class="form-group">
              <label for="">Pilih File</label>
              <input type="file" accept="image/jpg" @change="previewFile">
            </div>
            <div>
              <button type="submit" class="btn btn-primary" :disabled="loadingUpload">
                {{ loadingUpload ? "Uploading..." : "Upload Bukti Pembayaran" }}
              </button>
            </div>
          </form>
        </div>

        <div class="registered" v-show="data.id && !data.is_validated && !data.alasan_ditolak">

          <div style="text-align: center;" v-if="!uploadDeposit">
            <p style="margin-bottom: 0;">
              <img src="https://img.icons8.com/?size=48&id=sz8cPVwzLrMP&format=png" style="border: 0;margin:0;" />
            </p>
            <h3>Pendaftaran Berhasil</h3>
            <p>
              Silakan lakukan pembayaran deposit untuk kepastian kehadiran di gedung wisuda.
            </p>

            <button type="button" class="btn-primary" @click="uploadDeposit = true;">
              Upload bukti deposit
            </button>
          </div>

          <p class="notif" v-if="uploadDeposit">
            <b>Ketentuan: </b> <br />
          <ol style="padding-left: 20px;">
            <li>
              Silakan lakukan deposit sebesar Rp. 500.000 untuk kepastian kehadiran di
              gedung wisuda.
            </li>
            <li>Transfer Biaya deposit ke rek <b>BRI 0098-01-002137-30-3 a.n
                PSPP Penerbangan</b>.</li>
            <li>Upload bukti transfer biaya deposit melalui form di bawah ini</li>
            <li>Menerima pengesahan / validasi sebagai peserta wisuda tahun 2025
              berupa tanda bukti peserta dan nomor kursi wisuda.</li>
            <li>
              Pengembalian Deposit dilakukan dengan ketentuan sebagai berikut
              <ul>
                <li>Mengembalikan Toga (Samir tidak dikembalikan).</li>
                <li>Tanggal 23 April 2025 pengembalian Toga dilakukan di lokasi Wisuda pada
                  pukul 17.00 – 20.00 WIB.</li>
                <li>
                  Deposit ditransfer pada tanggal 24 April 2025 ke Nomor Rekening yang
                  tertera di Aplikasi pendaftaran Wisuda.
                </li>
              </ul>
            </li>
            <!-- <li>Batas transfer deposit pada tanggal 5 Maret 2022</li> -->
          </ol>
          </p>

          <div v-if="data.bukti_bayar">
            <p>
              <b>Upload Bukti Pembayaran Berhasil.</b> <br />
              Data pembayaran Anda akan segera divalidasi.
            </p>
            <img :src="data.bukti_bayar" width="100%">
            <br /><br />
          </div>
          <form @submit.prevent="upload" style="margin-top: 30px;" v-if="!data.bukti_bayar && uploadDeposit">
            <div v-show="filePrev">
              <img :src="filePrev" width="100%">
              <br /><br />
            </div>
            <div class="form-group">
              <label for="">Pilih File</label>
              <input type="file" accept="image/jpg" @change="previewFile">
            </div>
            <div>
              <button type="submit" class="btn btn-primary" :disabled="loadingUpload">
                {{ loadingUpload ? "Uploading..." : "Upload Bukti Pembayaran" }}
              </button>
            </div>
          </form>
        </div>

        <div class="validated" v-if="data.id && data.is_validated">
          <h3 style="display: flex; align-items: center; justify-content: center">
            Data Sudah Tervalidasi
            <img src="/static/icons/checked.svg" width="20" style="margin-left: 10px" />
          </h3>
          <p>
            <img style="padding: 0; margin: 0" :src="'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=' +
              data.no_booking +
              '&choe=UTF-8'
              " />
          </p>
          <h2 style="margin-top: -15px">No Kursi: {{ data.no_kursi.padStart(3, '0') }}</h2>
          <!-- <h1>No Kursi : {{ data.no_kursi }}</h1> -->
          <br />
          <p class="notif">Tunjukkan pada saat registrasi</p>

          <!-- <h3>Tata Tertib Wisuda</h3>
          <div>
            <img src="http://assets.pspp-integrated.com/tatib-wisuda-0322-0001.jpg" width="100%">
            <img src="http://assets.pspp-integrated.com/tatib-wisuda-0322-0002.jpg" width="100%">
            <img src="http://assets.pspp-integrated.com/tatib-wisuda-0322-0003.jpg" width="100%">
          </div> -->
        </div>

        <div class="unregistered" v-show="data.unregistred">
          <!-- <h3 style="text-align: center;margin-top:70px;">
            Pendaftaran Wisuda sudah ditutup</h3> -->

          <p class="notif">
            <b>Ketentuan: </b> <br />
          <ol style="padding-left: 20px;">
            <li>Peserta Wisuda tahun 2025 adalah angkatan n Desember 2022, Juni 2023,
              Desember 2023 dan Juni 2024</li>
            <li>
              Pendaftaran Wisuda tahun 2025 dibuka dengan 2 Gelombang:
              <br />
              <b>Gelombang 1: Periode 1 Januari – 21 Februari 2025</b>
              <ul>
                <li>Bebas biaya pendaftaran</li>
                <li>Membayar deposit sejumlah Rp 500.000</li>
              </ul>
              <b>Gelombang 2: Periode 3 Maret – 11 April 2025</b>
              <ul>
                <li>Biaya pendaftaran Wisuda sejumlah Rp 500.000</li>
                <li>Membayar deposit sejumlah Rp 500.000</li>
              </ul>
            </li>
            <li>Setiap Wisudawan hanya diperkenankan mendaftarkan 1 (satu) pendamping saja untuk hadir pada acara wisuda
            </li>
            <li>Pendaftar dianggap SAH apabila telah mendapatkan kode tanda peserta wisuda 2025</li>
            <li>Tidak melayani pendaftaran wisuda lebih / lewat dari tanggal 11 April 2025 dengan alasan apapun</li>
          </ol>
          </p>
          <form @submit.prevent="daftar">
            <div class="form-group">
              <label for="">Nama Lengkap</label>
              <input type="text" v-model="siswa.nama_lengkap" readonly />
            </div>
            <div class="form-group">
              <label for="">No Daftar</label>
              <input type="text" v-model="siswa.no_daftar" readonly />
            </div>
            <div class="form-group">
              <label for="">No HP Aktif</label>
              <input type="tel" v-model="siswa.no_hp" />
            </div>
            <div class="form-group">
              <label for="">Nama Pendamping (1 pendamping)</label>
              <input type="text" v-model="siswa.nama_pendamping_1" />
            </div>
            <div class="form-group">
              <label for="">No HP Pendamping</label>
              <input type="tel" v-model="siswa.no_hp_pendamping_1" />
            </div>
            <div>
              <button type="submit" class="btn btn-primary" :disabled="loadingButton">
                {{ loadingButton ? "MENDAFTARKAN..." : "DAFTAR WISUDA" }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- <p>PT Abbe Mandiri Investama turut mendukung acara Wisuda PSPP dengan memberikan harga terbaik untuk kebutuhan
        wisata.</p> -->

      <iframe width="100%" height="270" style="margin-bottom: 30px;" src="https://www.youtube.com/embed/D4y-rLzViC8"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import Back from "./icons/Back.vue";
import Loading from "./icons/Loading.vue";

import moment from "moment";
import axios from "axios";
import * as api from "../api/Api";

export default {
  name: "Sertifikat",
  components: {
    Back,
    Loading,
  },
  data() {
    return {
      loading: false,
      loadingButton: false,
      loadingUpload: false,
      fiturFlag: true,

      uploadDeposit: false,

      data: {},
      siswa: {
        id: this.$user.id,
        no_daftar: this.$user.no_daftar,
        nama_lengkap: this.$user.nama,
        no_hp: this.validateNoHp(this.$user.no_hp),
      },
      filePrev: "",
      file: null,
    };
  },
  methods: {
    moment,
    validateNoHp(no_hp) {
      return no_hp.replace("+", "").replace("62", "0");
    },
    cekFiturflag() {
      this.loading = true;
      api
        .get("/v1/FiturFlagController/get?fitur=pendaftaran_wisuda")
        .then((r) => {
          if (r.data.status == 1) {
            this.load();
          } else {
            this.loading = false;
            this.fiturFlag = false;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    load() {
      this.loading = true;
      api
        .get("/v1/wisuda/PendaftaranController/get?id_siswa=" + this.$user.id)
        .then((r) => {
          this.loading = false;
          if (r.valid == true) {
            if (!r.data) {
              this.data = {
                unregistred: true,
              };
            } else {
              this.data = r.data;
            }
          } else {
            this.data = {
              invalid: true,
              message: r.message,
            };
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    previewFile(e) {
      const [file] = e.target.files;
      this.file = file;
      if (file) {
        this.filePrev = URL.createObjectURL(file);
      }
    },
    upload() {
      if (!this.file) {
        alert("Silakan pilih bukti pembayaran");
        return;
      }

      const data = new FormData();
      data.append("id_siswa", this.siswa.id);
      data.append("bukti_bayar", this.file);

      const config = {
        onUploadProgress: function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          // self.file[field] = percentCompleted + "%";

          // if (percentCompleted >= 100) {
          //   self.file[field] = "Menyelesaikan";
          // }
        },
      };

      this.loadingUpload = true;

      axios
        .post(
          "https://api.pspp-integrated.com/api/v1/wisuda/PendaftaranController/upload",
          data,
          config
        )
        .then(function (res) {
          location.reload();
        })
        .catch(function (err) {
          this.loadingUpload = false;
          console.log(err.message);
        });
    },
    daftar() {
      if (!this.siswa.no_hp) {
        alert("No HP harus diisi");
        return;
      }
      if (!this.siswa.nama_pendamping_1) {
        alert("Nama Pendamping harus diisi");
        return;
      }
      if (!this.siswa.no_hp_pendamping_1) {
        alert("No HP Pendamping harus diisi");
        return;
      }

      this.loadingButton = true;
      api
        .post("/v1/wisuda/PendaftaranController/submit", this.siswa)
        .then((r) => {
          this.loadingButton = false;
          this.load();
        })
        .catch((e) => {
          alert("Terjadi kesalahan di server");
          this.loadingButton = false;
        });
    },
  },
  mounted() {
    this.cekFiturflag();
  },
};
</script>

<style scoped lang="scss">
.wisuda {
  .navbar {
    background: #fff;
    color: #333;
    padding: 15px;
    font-weight: 600;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-bottom: 1px solid #ccc;
  }

  .body {
    background: #fff;
    padding: 15px;
  }

  .radio-group {
    display: flex;
    margin-top: 15px;
    margin-bottom: 30px;

    label {
      display: flex;
      margin-right: 30px;
    }

    input {
      width: 30px;
      transform: scale(1.5);
      margin-left: 0;
    }
  }

  .loading {
    margin-top: 100px;

    p {
      text-align: center;
    }
  }

  .registered {
    p {
      line-height: 24px;
      font-size: 14px;
    }
  }

  .validated {
    text-align: center;

    p,
    h1 {
      margin: 0;
    }
  }

  p.notif {
    font-size: 13px;
    background: rgba(255, 239, 93, 0.479);
    padding: 10px;
    border: 1px solid rgb(250, 227, 100);
    border-radius: 6px;
    margin-bottom: 20px;
    text-align: left;

    ol {
      li {
        margin-bottom: 5px;
      }
    }
  }

  img {
    border: 1px solid #ccc;
    margin-bottom: 30px;
  }
}
</style>
